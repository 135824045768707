<template>
  <div>
    <!-- <v-container fluid class="down-top-padding"> -->
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
    <ServerError  />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class=" elevation-1">
                  Settings
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      solo
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="user"
                  :search="search"
                   :items-per-page="count"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:item._id="{ item }">
                    <v-icon small class="mr-2" @click="editset(item)">
                      mdi-pencil
                    </v-icon>
                    <!-- <v-icon small class="mr-2" @click="info(item._id)">
                      mdi-eye
                    </v-icon> -->
                    <!-- <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-2"
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Rating?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog> -->
                  </template>
                  <template v-slot:top>
                    <v-dialog
                      v-model="editdialog"
                      :retain-focus="false"
                      max-width="500px"
                    >
                      <v-card>
                        <v-card-title>
                          <span class="headline">Edit Settings </span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="editingitem.Value"
                                  :label="editingitem.Name"
                                ></v-text-field>
                              </v-col>
                              <!-- <v-col cols="12" sm="6" md="6">
                                <v-textarea
                                  v-model="editingitem.comment"
                                  label=" Comment"
                                  required
                                  hint="Text"
                                ></v-textarea>
                              </v-col> -->
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="editdialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="edit()">
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>
                <!-- <div class="pt-2">
                  <v-pagination
                    :length="pages"
                    :total-rows="totalData"
                    v-model="currentPage"
                  ></v-pagination>
                </div> -->
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <!-- </v-container> -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      ServerError: false,
      showsnackbar: false,
      msg: null,
      dialog: false,
      editdialog: false,
      appLoading: false,
      search: "",
      currentPage: 1,
      pages: 0,
      count: 30,
      totalData: 0,
      totalRows: 0,
      editingitem: [],
      headers: [
        { text: "Name", value: "Name" },
        { text: "Value", value: "Value" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      user: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/settings/getlist",

        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },
    editset(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      // this.editid=item;
      var data = {};
      data["id"] = this.editingitem._id;
      data["Value"] = this.editingitem.Value;
      axios({
        url: "/settings/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.params.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.editdialog = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/settings/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    sellerinfo(sid) {
      this.$router.push({ name: "sellerDetails", params: { sellerid: sid } });
    },
  },
};
</script>

<style>
</style>